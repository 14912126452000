// #################################################
//  Use btn/body className and number default active tab
// example: initTabs('tabs__btn', 'tabs__body', '1');
// #################################################
function tab(btnClass, bodyClass, activeTabNumber, parent) {

    const parents = document.querySelectorAll(`.${parent}`);
    parents.forEach((el) => {
        initTabs(btnClass, bodyClass, activeTabNumber, el);
    });
    function initTabs(btnClass, bodyClass, activeTabNumber, parents) {
        let p = parents;
        const btns = p.querySelectorAll(`.${btnClass}`);
        const bodys = p.querySelectorAll(`.${bodyClass}`);
        // Проверка на существование кнопка\таб
        if (btns.length > 0 && bodys.length > 0) {
            if (activeTabNumber) {
                bodys[activeTabNumber].classList.add(`${bodyClass}_active`);
                btns[activeTabNumber].classList.add(`${btnClass}_active`);
            }
            // Переключение табов
            btns.forEach((el, key) => {
                el.addEventListener("click", (event) => {
                    event.preventDefault();
                    btns.forEach((btn) => {
                        btn.classList.remove(`${btnClass}_active`);
                    });
                    bodys.forEach((tab) => {
                        tab.classList.remove(`${bodyClass}_active`);
                    });
                    el.classList.add(`${btnClass}_active`);
                    bodys[key].classList.add(`${bodyClass}_active`);
                });
            });
        }
    }
}
tab("card__tab-btn", "card__tab-img", "0", "product__card")
tab("product-slider__btn-container", "product-slider__tab-body", "0", "product-slider__row")
tab("product-slider__slider-preview-img", "product-slider__slider-img", "0", "product-slider__tab-body")